import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress'
import { lazyWithPreload } from '../services/prefetch/prefetch.ts';
import NewPasswordPage from '../pages/RecoverPassword/NewPasswordPage.tsx';
import { useSelector } from 'react-redux';
const Privacypolicy = lazyWithPreload(() => import('../pages/Privacypolicy/index'));
const Termofuse = lazyWithPreload(() => import('../pages/Termofusepage'));
const Register = lazyWithPreload(() => import('../pages/SignUpAboutYou/index.tsx'));;
const VerifyEmail = lazyWithPreload(() => import('../pages/SignUpVerifyEmail/index.tsx'));
const StudentEducation = lazyWithPreload(() => import('../pages/SignUpEducationalDetails/index.tsx'));
const MoreAboutStudents = lazyWithPreload(() => import('../pages/SignUpPersonalDetails/index.tsx'));
const ContactUs = lazyWithPreload(() => import('../pages/ContactUs/index.tsx'));
const SignUpOptionsPage = lazyWithPreload(() => import('../pages/SignUp/index.tsx'));
const CounselorRegister = lazyWithPreload(() => import('../pages/SignUp/CounselorSignUp/CounselorDetails/index.tsx'));
const AboutUs = lazyWithPreload(() => import('../pages/AboutUs/index.tsx'));
const Scholarship101 = lazyWithPreload(() => import('../pages/Scholarship101/index.tsx'));
const LazyLogin = lazyWithPreload(() => import('../pages/Login'))
const NoPageFound = lazyWithPreload(() => import('../pages/NotFoundPage/NotFoundPage.tsx'));
const SecurityPage = lazyWithPreload(() => import('../pages/SecurityPage/index.tsx'));
const Home = lazyWithPreload(() => import('./../pages/Home'));
const FAQ = lazyWithPreload(() => import('../pages/FAQ/index.tsx'));
const RecoverPasswordComponent = lazyWithPreload(() => import('../pages/RecoverPassword/index.tsx'));
const Counselor = lazyWithPreload(() => import('../pages/Counselor/Counselor/index.tsx'));
const LearningCenterOut = lazyWithPreload(() => import('../pages/Dashboard/LearningCenter/Learning.tsx'));
const LearningCenterInfo = lazyWithPreload(() => import('../Components/LearningCenter/LearningCenterInfo.tsx'));
import { LoadingFallback } from '../Components/LoadingFallback/LoadingFallback.tsx';
const VideoPlayer = lazyWithPreload(() => import('../pages/Video/index.tsx'));
const ViewPublicScholarships = lazyWithPreload(() => import('../pages/CounselorDashboard/ListManger/ScholarshipPage/Embed/PublicScholarshipView/index.tsx'));




const PublicRoutes = () => {
    const loginDataFromRedux = useSelector((state) => state?.login);
    const { loginDetails } = loginDataFromRedux;
    const userRole = loginDetails?.data?.user?.usersRoles?.[0]?.role?.normalizedName;
    return (
        <Routes>
            <Route path="/aboutUs" element={<React.Suspense fallback={<LoadingFallback/>}><AboutUs /></React.Suspense>}></Route>
            <Route path='/privacy-policy' element={<React.Suspense fallback={<LoadingFallback/>}><Privacypolicy /></React.Suspense>}> </Route>
            <Route path='/signup' element={<React.Suspense fallback={<LoadingFallback/>}><SignUpOptionsPage />    </React.Suspense>} ></Route>
            <Route path='/signup-counselor' element={<React.Suspense fallback={<LoadingFallback/>}><CounselorRegister />   </React.Suspense>} ></Route>
            <Route path='/signup-student' element={<React.Suspense fallback={<LoadingFallback/>}><Register />    </React.Suspense>} ></Route>
            <Route path='/signUp/verifyEmail' element={<React.Suspense fallback={<LoadingFallback/>}><VerifyEmail />  </React.Suspense>} ></Route>
            <Route path='/signUp/yourEducationDetails' element={<React.Suspense fallback={<LoadingFallback/>}><StudentEducation /> </React.Suspense>} ></Route>
            <Route path='/signUp/moreAboutYou' element={<React.Suspense fallback={<LoadingFallback/>}><MoreAboutStudents /></React.Suspense>} ></Route>
            <Route path='/terms' element={<React.Suspense fallback={<LoadingFallback/>}><Termofuse /></React.Suspense>}> </Route>
            <Route path='/users/login' element={<React.Suspense fallback={<LoadingFallback/>}><LazyLogin /></React.Suspense>}> </Route>
            <Route path='/users/forgotPassword' element={<React.Suspense fallback={<LoadingFallback/>}><RecoverPasswordComponent /></React.Suspense>}> </Route>
            <Route path='/users/forgotPassword/otp' element={<React.Suspense fallback={<LoadingFallback/>}><VerifyEmail /></React.Suspense>}> </Route>
            <Route path="/users/forgotPassword/new" element={<React.Suspense fallback={<LoadingFallback/>}><NewPasswordPage /></React.Suspense>}></Route>
            <Route path="/contact" element={<React.Suspense fallback={<LoadingFallback/>}><ContactUs /></React.Suspense>}></Route>
            <Route path="/aboutCounselor/" element={<React.Suspense fallback={<LoadingFallback/>}><Counselor /></React.Suspense>}></Route>
            <Route path="/faq" element={<React.Suspense fallback={<LoadingFallback/>}><FAQ /></React.Suspense>}></Route>
            <Route path="/security-page" element={<React.Suspense fallback={<LoadingFallback/>}><SecurityPage /></React.Suspense>}></Route>
            <Route path="/learningCenter" element={<React.Suspense fallback={<LoadingFallback/>}><LearningCenterOut /></React.Suspense>}></Route>
            <Route path="/learningCenter/:moreInfo" element={<React.Suspense fallback={<LoadingFallback/>}><LearningCenterInfo /></React.Suspense>}></Route>
            <Route path="/learning/rapid-essay-method" element={<Navigate to="/learningCenter/Rapid-Essay-Method" />} />
            <Route path="/video" element={<React.Suspense fallback={<LoadingFallback/>}><VideoPlayer /></React.Suspense>}></Route>
            <Route path="/scholarships/:scholarshipId" element={<React.Suspense fallback={<LoadingFallback/>}><ViewPublicScholarships /></React.Suspense>}></Route>
            {/* <Route path="/cms/scholarship-101" element={<React.Suspense fallback={<LoadingFallback/>}><Scholarship101 /></React.Suspense>}></Route> */}
            <Route path="*" element={<NoPageFound />} />
        </Routes>
    )
};

export default PublicRoutes;